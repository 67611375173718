<template>
  <div class="user">
    <div class="container">
      <div class="form_info">
        <el-form ref="form" :model="queryInfo" label-width="80px">
          <div class="form">
            <div class="comble_txt">
              <el-form-item label="报告类型:" prop="">
                <el-radio-group v-model="queryInfo.reportType" @change="changeSearchangeSearch">
                  <el-radio-button label="">全部</el-radio-button>
                  <el-radio-button label="单份报告">单份报告</el-radio-button>
                  <el-radio-button label="报告合集">报告合集</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="报告类别:" prop="">
                <el-radio-group v-model="queryInfo.generalVersion" @change="changeSearchangeSearch">
                  <el-radio-button label="">全部</el-radio-button>
                  <el-radio-button label="0">通用报告</el-radio-button>
                  <el-radio-button label="1">个性化报告</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="出版日期:" prop="">
                <el-date-picker
                  @change="changeSearchangeSearch"
                  value-format="yyyy-MM-dd"
                  v-model="queryInfo.releaseTime"
                  type="date"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label=" " label-width="0px" style="margin-bottom: 0">
                <div class="input-with">
                  <div>
                    <el-button type="primary" @click="toPage('contentEditReport', {})"
                      >新增报告</el-button
                    >
                    <el-button style="margin-left: 5px" @click="SetGeneral">批量设置智参</el-button>

                    <el-upload
                      style="display: inline-block; margin-left: 10px"
                      action
                      class="upload-demo"
                      ref="upload"
                      multiple
                      :show-file-list="false"
                      :before-upload="
                        (res) => {
                          return beforeUpload(res)
                        }
                      "
                      :http-request="
                        (res) => {
                          return upload(res)
                        }
                      "
                    >
                      <!-- <el-button>批量下载</el-button> -->

                      <el-button class="el_buttom" slot="trigger">批量导入</el-button>
                    </el-upload>
                    <el-button style="margin-left: 5px" @click="updateOnShelfReport"
                      >批量发布</el-button
                    >
                    <el-button style="margin-left: 5px" @click="updateOffShelfReport"
                      >批量下架</el-button
                    >
                    <el-button @click="updateReportGeneralVersionHandle">设置通用版报告</el-button>

                    <el-checkbox v-model="checkedAll" style="margin-left: 10px" @change="selectAll"
                      >全选</el-checkbox
                    >
                    <!-- <el-checkbox
                      v-model="checked"
                      style="margin-left: 10px"
                      @change="checkGeneralVersion"
                      >只查看通用版活动</el-checkbox
                    > -->
                  </div>
                  <div>
                    <el-input
                      placeholder="报告名称、报告简介"
                      v-model="queryInfo.queryConditions"
                      class="input-with-select"
                    >
                      <el-button class="seach" slot="append" @click="changeSearchangeSearch"
                        >搜索</el-button
                      >
                    </el-input>
                  </div>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>

      <div
        class="tab_txt"
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
      >
        <div style="display: flex; align-items: center; padding-bottom: 10px">
          <div style="padding-right: 50px">
            <span>当前条件下共查出{{ total }}篇报告</span>
          </div>
          <div
            v-for="(item, index) in orderList"
            :key="index"
            style="display: flex; align-items: center; margin-right: 30px"
          >
            {{ item.name }}
            <div class="main">
              <span
                class="arrUp"
                @click="clickUp1(item)"
                :style="{ 'border-bottom-color': item.color1 }"
              ></span>
              <span
                class="arrDown"
                @click="clickDown1(item)"
                :style="{ 'border-top-color': item.color2 }"
              ></span>
            </div>
          </div>
        </div>
        <div class="storeCenter_item" v-for="(item, index) in listData" :key="index">
          <div class="item_checkbox">
            <el-checkbox
              @change="latechange($event, item)"
              v-model="item.checked"
              label=""
            ></el-checkbox>
          </div>
          <div class="storeCenter_item_top">
            <div class="storeCenter_item_top_left">
              <div class="banner_box">
                <img v-if="item.coverImage" :src="item.coverImage" alt="" />
                <img v-else src="../../../assets/image/Group 3237.png" alt="" />
                <!-- <span class="generalVersion">
                  {{ ['通用版', '个性版', '全部'][item.generalVersion * 1] }}</span
                > -->
              </div>
              <div class="storeCenter_item_top_left_flex">
                <div style="display: flex; align-items: center">
                  <img
                    width="16"
                    height="16"
                    v-if="item.reportType == '报告合集'"
                    src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/%E5%8E%8B%E7%BC%A9%E5%8C%85%201.png"
                    alt=""
                  />
                  <img
                    width="16"
                    height="16"
                    v-else
                    src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/PDF%20%281%29%201.png"
                    alt=""
                  />
                  <h5 @click="download(item)" class="h5_name">
                    {{ item.reportName }}
                  </h5>
                  <img width="16" height="16" src="../../../assets/image/Vector-1.png" alt="" />
                  <span style="margin-left: 5px" v-if="item.originalPrice > 0"
                    >{{ item.originalPrice }}元</span
                  >
                  <span style="margin-left: 5px" v-else>免费</span>
                </div>

                <div class="storeCenter_item_top_left_flex_tag">
                  <span style="display: flex" v-if="item.labelList">
                    <span v-for="(o, index) in item.labelList" :key="index">
                      <span v-if="index < 2" class="office">{{ o }}</span>
                    </span>
                  </span>

                  <el-popover placement="right" width="430" trigger="hover">
                    <div
                      class="hidden_label_box"
                      style="
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-content: center;
                        flex-wrap: wrap;
                      "
                    >
                      <span
                        class="office"
                        style="
                          border: 1px solid #559eff;
                          color: #4e93fb;
                          box-sizing: border-box;
                          padding: 1px 6px;
                          display: block;
                          font-size: 14px;
                          font-weight: 400;
                          border-radius: 4px;
                          margin-right: 10px;
                          margin-bottom: 10px;
                        "
                        v-for="(o, index) in item.labelList"
                        :key="index"
                        >{{ o }}</span
                      >
                    </div>
                    <span
                      class="office"
                      slot="reference"
                      v-if="item.labelList && item.labelList.length > 2"
                      >{{ item.labelList.length }}+</span
                    >
                  </el-popover>
                </div>
                <div>{{ item.sponsor }}</div>
                <div v-if="item.releaseMechanism" class="releaseMechanism">
                  出版机构：{{ item.releaseMechanism }}
                </div>
                <div @click="download(item)" class="h5_names" style="color: #ff7d18">
                  {{ item.reportName }}
                </div>
              </div>
            </div>
            <div class="storeCenter_item_top_center">
              <div class="count_box" @click="PushreportCompany(item)">
                <div>{{ item.pushCompanyNumber }}</div>
                <div>已推送</div>
              </div>
              <div class="count_box" @click="downloadCount(item)">
                <div>{{ item.uploadUserNumber }}</div>
                <div>下载</div>
              </div>
              <div class="count_box" @click="viewUser(item)">
                <div>{{ item.viewUserNumber }}</div>
                <div>查看</div>
              </div>
              <div class="count_box" @click="ShareUser(item)">
                <div>{{ item.shareUserNumber }}</div>
                <div>分享</div>
              </div>
              <!-- <div class="count_box">
                <div>{{ item.collectUserNumber }}</div>
                <div>收藏用户数</div>
              </div> -->
            </div>
            <div class="storeCenter_item_top_right">
              <div class="operation_area">
                <el-button type="primary" @click="contentConfig(item, 2)">算法配置</el-button>
                <el-button type="info" plain @click="contentConfig(item, 1)">手动配置</el-button>

                <el-button type="info" @click="clcikgensing(item)" plain>配置智参 </el-button>
                <el-button type="info" @click="clciklabel(item)" plain
                  >报告标签
                  <span v-if="item.labelList">({{ item.labelList.length }})</span>
                </el-button>
                <span class="edit" @click="toPage('contentEditReport', item)">编辑</span>

                <span v-if="item.isShelf == 1" class="edit" @click="OnShelf(item)">上架</span>
                <span v-if="item.isShelf == 0" class="frozen" @click="OffShelf(item)">下架</span>
                <span v-if="item.isDelete == 0" class="edit" @click="delcompany(item)">冻结</span>
                <span v-if="item.isDelete == 1" class="frozen" @click="cancelcompany(item)"
                  >激活</span
                >
              </div>
              <div class="details">操作人：{{ item.createName }}</div>
              <div class="details">最近操作时间：{{ item.updateTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="new_page" v-if="listData.length">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      title="批量配置智参中心"
      :visible.sync="dialogVisible"
      width="627px"
      class="account_dialog"
    >
      <el-form label-width="100px" :model="querygecil" ref="form">
        <el-form-item label="所在地区:" prop="">
          <el-select v-model="pageParam.province" placeholder="请选择省份" @change="citychange">
            <el-option :value="''" label="全部"></el-option>
            <el-option
              v-for="item in operatingList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <div style="display: inline-block; width: 10px"></div>
          <el-select v-model="pageParam.city" placeholder="请选择地区" @change="domGinsengPage">
            <el-option :value="''" label="全部"></el-option>
            <el-option
              v-for="item in operatingLists"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="中心名称：" prop="link">
          <div class="disinput">
            <el-input
              placeholder="请输入智参中心名称"
              v-model="pageParam.queryConditions"
              class="input-with-select"
            >
              <el-button
                style="
                  border: 1px solid #448aff;
                  background-color: #448aff;
                  color: #fff;
                  border-radius: 0;
                "
                class="seach"
                slot="append"
                @click="domGinsengPage()"
                >搜索</el-button
              >
            </el-input>
          </div>
        </el-form-item>
      </el-form>
      <el-table
        ref="multipleTable"
        :data="listgisen"
        v-loading="loadingisen"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
        stripe
        width="100%"
        class="in_table"
        @selection-change="handleSelectionChange"
        row-key="id"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
      >
        <el-table-column type="selection" align="center" :reserve-selection="true" />
        <el-table-column label="智参中心名称" prop="zcName" align="center"></el-table-column>
        <el-table-column label="所在地区" prop="" align="center">
          <template v-slot="{ row }">
            <span>{{ row.province }}-{{ row.city }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="pageParam.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="pageParam.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totaldins"
          @size-change="handleSize"
          @current-change="handleCurrent"
        />
      </div>
      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="checkClick()">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="配置智参中心"
      :visible.sync="dialogVisibles"
      width="700px"
      class="account_dialog"
    >
      <el-form label-width="100px" :model="pageParam" ref="form">
        <el-form-item label="所在地区:" prop="">
          <el-select v-model="pageParam.province" placeholder="请选择省份" @change="citychange">
            <el-option :value="''" label="全部"></el-option>
            <el-option
              v-for="item in operatingList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <div style="display: inline-block; width: 10px"></div>
          <el-select v-model="pageParam.city" placeholder="请选择地区" @change="wisdomGinsengPage">
            <el-option :value="''" label="全部"></el-option>
            <el-option
              v-for="item in operatingLists"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="中心名称：" prop="link">
          <div class="disinput">
            <el-input
              placeholder="请输入智参中心名称"
              v-model="pageParam.queryConditions"
              class="input-with-select"
            >
              <el-button
                style="
                  border: 1px solid #448aff;
                  background-color: #448aff;
                  color: #fff;
                  border-radius: 0;
                "
                class="seach"
                slot="append"
                @click="wisdomGinsengPage()"
                >搜索</el-button
              >
            </el-input>
          </div>
        </el-form-item>
        <div class="add_search">
          <div class="left">
            <el-button class="add" @click="isShelfzone">批量上架</el-button>
            <el-button style="margin-right: 5px" @click="isShelfone">批量下架</el-button>
          </div>
        </div>
      </el-form>
      <el-table
        ref="multipleTables"
        v-loading="loadingisen"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
        :data="listgisen"
        stripe
        width="100%"
        class="in_table"
        @selection-change="handleSelectionChange"
        row-key="id"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
      >
        <el-table-column type="selection" align="center" :reserve-selection="true" />
        <el-table-column label="智参中心名称" prop="zcName" align="center"></el-table-column>
        <el-table-column label="所在地区" prop="" align="center">
          <template v-slot="{ row }">
            <span>{{ row.province }}-{{ row.city }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作人" prop="userName" align="center"></el-table-column>

        <el-table-column label="操作时间" prop="createTime" align="center"></el-table-column>

        <el-table-column label="操作" prop="" align="center">
          <template v-slot="{ row }">
            <span v-if="row.isShelf == 1" @click="isShelfzone(row, 1)" class="support">上架</span>
            <span v-if="row.isShelf == 0" @click="isShelfone(row, 1)" class="office">下架</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="pageParam.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="pageParam.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totaldins"
          @size-change="handleSizeChanges"
          @current-change="handleCurrentChanges"
        />
      </div>
      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="dialogVisibles = false">确 定</el-button>
        <el-button @click="dialogVisibles = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  reportlist,
  updateReportGeneralVersion,
  cancelFreezeReport,
  uploadFile,
  freezeReport,
  importReport,
  updateOffShelfReport,
  updateOnShelfReport,
  wisdomGinsengReportPage,
  wisdomGinsengOffSelfReport,
  wisdomGinsengOnSelfReport,
  updateGeneralVersionReport,
  domGinsengPage
} from '@/api/content'
import { getByFatherIdt, getProvince } from '@/api/demand'

// import { table } from 'console'
const defaultQueryInfo = Object.freeze({
  generalVersion: '',
  orderByParam: 't.create_time desc',
  pageNum: 1,
  pageSize: 10,
  queryConditions: '',
  releaseTime: '',
  reportType: ''
})
export default {
  name: 'Userment',
  data() {
    return {
      checked: false,
      checkedAll: false,
      labelActiveId: [],
      labelActiveList: [{}],
      ruleForm: {
        date: null
      },
      rules: {
        date: [{ required: true, message: '请选择账号周期', trigger: 'blur' }]
      },
      value2: '',
      dialogVisible: false,
      dialogVisibles: false,
      accountDialogVisible: false,
      currentId: null,
      labelList: [],
      ids: [],
      idsList: [],
      enterpriseIds: [],
      enterpriseIdsList: [],
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: false,
      //配置智参
      loadingisen: false,
      // 总条数
      total: 0,
      codelist: [],
      uploadMark: null,

      orderList: [
        {
          name: '按查看次数',
          color1: '',
          color2: '#C6C6C6',
          orderByParam: 'viewUserNumber'
        },
        {
          name: '按报告原价',
          color1: '',
          color2: '#C6C6C6',
          orderByParam: 't.original_price'
        },
        {
          name: '按出版日期',
          color1: '',
          color2: '#C6C6C6',
          orderByParam: 't.release_time'
        },
        {
          name: '按查看人数',
          color1: '',
          color2: '#C6C6C6',
          orderByParam: 'viewUserNumber'
        },
        {
          name: '按分享人数',
          color1: '',
          color2: '#C6C6C6',
          orderByParam: 'shareUserNumber'
        },
        {
          name: '按下载人数',
          color1: '',
          color2: '#C6C6C6',
          orderByParam: 'uploadUserNumber'
        }
      ], //排序
      querygecil: {}, //批量设置智参中心筛选条件
      pageParam: {
        city: null,
        id: null,
        orderByParam: null,
        pageNum: 1,
        pageSize: 10,
        province: null,
        queryConditions: null
      }, //配置智参中心
      operatingList: [], //城市
      operatingLists: [], //省份
      listgisen: [],
      idgisen: [],
      totaldins: 0,
      bol: false
    }
  },
  created() {
    if (!this.bol) {
      this.search()
      this.bol = true
    }
    if(this.$route.query.changeNum){
      this.queryInfo.pageNum = Number(localStorage.getItem('paginationCurrentPage'))
      this.search()
    }else{
      this.queryInfo.pageNum = 1
      this.search()
    }
  },
  activated() {
    if (this.bol) {
      this.search()
    }
  },
  methods: {
    //进去城市
    async getByFatherIdt(id) {
      const res = await getByFatherIdt({ provinceCode: id })
      if (res.data.resultCode == 200) {
        this.operatingLists = res.data.data
      }
    },
    //城市
    async incity() {
      const res = await getProvince()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    //报告配置智参中心数据一览
    async wisdomGinsengPage() {
      this.loadingisen = true
      const { data: res } = await wisdomGinsengReportPage(this.pageParam)
      if (res.resultCode == 200) {
        this.listgisen = res.data.list
        this.totaldins = res.data.total
        this.loadingisen = false
      }
    },
    //分页查询智参中心
    async domGinsengPage() {
      delete this.pageParam.id
      this.loadingisen = true
      const { data: res } = await domGinsengPage(this.pageParam)
      if (res.resultCode == 200) {
        this.listgisen = res.data.list
        this.totaldins = res.data.total
        this.loadingisen = false
      }
    },
    // 分页资讯配置智参中心数据一览
    handleSizeChanges(val) {
      this.pageParam.pageSize = val
      this.wisdomGinsengPage()
    },
    // 分页资讯配置智参中心数据一览
    handleCurrentChanges(val) {
      this.pageParam.pageNum = val
      this.wisdomGinsengPage()
    },
    // 分页资讯配置智参中心数据一览
    handleSize(val) {
      this.pageParam.pageSize = val
      this.domGinsengPage()
    },
    // 分页资讯配置智参中心数据一览
    handleCurrent(val) {
      this.pageParam.pageNum = val
      this.domGinsengPage()
    },
    citychange(item) {
      this.operatingList.forEach((o) => {
        if (this.pageParam.province == o.name) {
          this.pageParam.city = ''
          if (item == '全部') {
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
            console.log(this.operatingLists, 'this.operatingLists ')
          } else {
            this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
          }

          // console.log(item, 'item', this.operatingList)
          // this.getByFatherIdt(o.code)
        }
      })
      this.search()
    },
    //上架智参中心
    isShelfzone(row, index) {
      if (index) {
        this.idgisen = [row.schemaName]
      }
      const query = {
        reportId: row.reportId,
        schemaNames: this.idgisen
      }
      if (this.idgisen.length > 0) {
        const that = this
        this.$alert('是否确认上架？')
          .then(function () {
            return wisdomGinsengOnSelfReport(query)
          })
          .then(() => {
            that.wisdomGinsengPage()
            that.idgisen = []
            this.$refs.multipleTables.clearSelection()
            this.$message.success('上架成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择智参中心')
      }
    },
    //下架智参中心
    isShelfone(row, index) {
      if (index) {
        this.idgisen = [row.schemaName]
      }
      const query = {
        reportId: row.reportId,
        schemaNames: this.idgisen
      }
      if (this.idgisen.length > 0) {
        const that = this
        this.$alert('是否确认下架？')
          .then(function () {
            return wisdomGinsengOffSelfReport(query)
          })
          .then(() => {
            that.wisdomGinsengPage()
            that.idgisen = []
            this.$refs.multipleTables.clearSelection()
            this.$message.success('下架成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择智参中心')
      }
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.idgisen = selection.map((item) => item.schemaName)
    },
    //批量设置报告
    checkClick() {
      if (this.ids.length > 0) {
        const query = {
          ids: this.ids,
          schemaNames: this.idgisen
        }
        const that = this
        this.$alert('是否确认设置成通用报告？')
          .then(function () {
            return updateGeneralVersionReport(query)
          })
          .then(() => {
            this.search()
            this.ids = []
            this.checkedAll = false
            this.dialogVisible = false
            this.idgisen = []
            this.$refs.multipleTable.clearSelection()
            this.$message.success('设置成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择报告')
      }
    },
    //批量导入
    async beforeUpload(file) {
      this.file = file
      this.filename = file.name
      // console.log(file, 'file', file.name.split('.'), file.name.split('.').splice(-1))
      const extension = file.name.split('.').splice(-1) == 'pdf'
      if (!extension) {
        this.$message.warning('上传模板只能是pdf格式')
        return
      }
      let reader = new FileReader()
      // console.log(reader, 'reader')
      reader.readAsDataURL(file)
      // console.log(reader.result, 'reader.result')

      let that = this
      reader.onload = function () {
        that.fileData = reader.result
      }
      return false
    },
    //上传
    async upload(params, index) {
      clearTimeout(this.uploadMark)
      const form = new FormData()
      form.append('files', params.file)

      const res = await uploadFile(form)
      if (res.data.resultCode === 200) {
        const result = await importReport({ reportKey: res.data.data })

        if (result.data.resultCode == 200) {
          this.search()
        } else {
          this.uploadMark = setTimeout(() => {
            this.$message.warning(res.data.message)
          }, 10000)
        }

        // this.form.reportFile = res.data.data
      }
    },
    async search() {
      this.loading = true
      const res = await reportlist(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
        this.loading = false

        this.listData.forEach((item) => {
          const bol = this.ids.find((el) => el.id == item.id)
          if (bol) {
            this.$set(item, 'checked', true)
          } else {
            this.$set(item, 'checked', false)
          }
          if (item.labelName != null) {
            item.labelName = item.labelName.split(',')
            item.labelName = item.labelName.slice(0, 3)
          }
        })
      }
    },
    //升序排序
    clickUp1(item) {
      this.queryInfo.orderByParam = item.orderByParam + ' ASC'
      this.search()
      item.color1 = '#0099ff'
      item.color2 = ''
    },
    //降序排序
    clickDown1(item) {
      this.queryInfo.orderByParam = item.orderByParam + ' DESC'
      this.search()
      item.color1 = ''
      item.color2 = '#0099ff'
    },
    //配置智参
    clcikgensing(row) {
      this.pageParam.id = row.id
      this.dialogVisibles = true
      this.wisdomGinsengPage()
      this.incity()
    },
    //批量绑定智参
    SetGeneral() {
      if (this.ids.length > 0) {
        this.dialogVisible = true
        this.domGinsengPage()
        this.incity()
      } else {
        this.$message.warning('请选择报告')
      }
    },
    //全选
    selectAll(bol) {
      if (bol) {
        if (this.ids.length != this.listData.length) {
          this.ids = this.listData.map((item) => {
            this.$set(item, 'checked', true)

            return item.id
          })
        }
      } else {
        this.ids = []
        this.listData.forEach((item) => {
          this.$set(item, 'checked', false)
        })
      }
      // console.log(this.ids, 'this.ids ')
    },
    updateReportGeneralVersionHandle() {
      if (this.ids.length > 0) {
        const that = this
        this.$alert('是否确认设置成通用报告？')
          .then(function () {
            that.loading = true
            return updateReportGeneralVersion(that.ids)
          })
          .then(() => {
            this.loading = false
            this.ids = []
            this.search()
            this.$message.success('设置成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择报告')
      }
    },
    //单个下架
    OffShelf(row) {
      const ids = []
      ids.push(row.id)

      this.$alert('是否确认下架报告？')
        .then(function () {
          return updateOnShelfReport(ids)
        })
        .then(() => {
          this.checkedAll = false
          this.ids = []
          this.search()

          this.$message.success('下架成功')
        })
        .catch(() => {})
    },
    //单个上架
    OnShelf(row) {
      const ids = []
      ids.push(row.id)

      this.$alert('是否确认上架报告？')
        .then(function () {
          return updateOffShelfReport(ids)
        })
        .then(() => {
          this.checkedAll = false
          this.ids = []
          this.search()

          this.$message.success('上架成功')
        })
        .catch(() => {})
    },
    //批量下架
    updateOffShelfReport() {
      if (this.ids.length > 0) {
        const that = this
        this.$alert('是否确认批量下架报告？')
          .then(function () {
            return updateOnShelfReport(that.ids)
          })
          .then(() => {
            this.search()
            this.ids = []
            this.checkedAll = false
            this.$message.success('下架成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择报告')
      }
    },
    //批量上架
    updateOnShelfReport() {
      if (this.ids.length > 0) {
        const that = this
        this.$alert('是否确认批量上架报告？')
          .then(function () {
            return updateOffShelfReport(that.ids)
          })
          .then(() => {
            this.search()
            this.ids = []
            this.checkedAll = false
            this.$message.success('上架成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择报告')
      }
    },
    //下载报告点击
    download(item) {
      if (item.reportType == '单份报告') {
        if (item) {
          window.open(item.reportFile)
        } else {
          this.$message.error('下载失败!')
        }
      } else {
        window.open(item.networkStorageLink)
      }
    },
    // 切换搜索列表的参数
    checkParams(key, val) {
      this.queryInfo[key] = val
      this.search()
    },
    checkParamsData(val) {
      this.queryInfo.activityStartTime = val[0]
      this.queryInfo.activityEndTime = val[1]
      this.search()
    },
    checkGeneralVersion(bol) {
      if (bol) {
        this.queryInfo.generalVersion = 0
      } else {
        this.queryInfo.generalVersion = null
      }
      this.search()
    },
    toPage(type, row) {
      if (row.isDelete != 1) {
        if (type == 'contentEditReport') {
          this.$router.push({
            path: '/content/editReport',
            query: {
              id: row.id,
              headTabActive: 0,
              pageNum: this.queryInfo.pageNum
            }
          })
        }
      } else {
        this.$message.warning('该报告已被冻结')
      }
    },
    //企业配置
    contentConfig(row, index) {
      this.$router.push({
        path: '/content/configureReport',
        query: {
          id: row.id,
          type: index
        }
      })
    },
    //标签
    clciklabel(row) {
      if (row.isDelete != 1) {
        this.$router.push({
          path: '/content/editReport',
          query: {
            id: row.id,
            headTabActive: 1
          }
        })
      } else {
        this.$message.warning('该报告已被冻结')
      }
    },

    afteraccountDialogHandle() {
      this.ruleForm.date = null
      this.accountDialogVisible = false
    },

    beforeAccountDialog(id) {
      this.currentId = id
      this.accountDialogVisible = true
    },

    //查看人数
    viewUser(row) {
      this.$router.push({
        path: '/report/listusertCheckPage',
        query: {
          id: row.id,
          pageType: 1
        }
      })
    },
    //分享人数
    ShareUser(row) {
      this.$router.push({
        path: '/report/ShareUser',
        query: {
          id: row.id,
          pageType: 2
        }
      })
    },
    //下载人数跳转
    downloadCount(item) {
      this.$router.push({
        name: 'downloadCount',
        query: { id: item.id }
      })
    },
    //已匹配企业数
    PushreportCompany(item) {
      this.$router.push({
        name: 'PushreportCompany',
        query: { id: item.id }
      })
    },

    //关联用户
    UserClick(item) {
      this.$router.push({
        name: 'CorrelateUser',
        query: {
          id: item.id,
          secretaryGeneralName: item.secretaryGeneralName,
          chamberCommerceName: item.chamberCommerceName
        }
      })
    },
    latechange(bol, row) {
      const index = this.ids.indexOf(row.id)
      if (index == -1) {
        this.ids.push(row.id)
        this.idsList.push(row)
      } else {
        this.ids.splice(index, 1)
        this.idsList.splice(index, 1)
      }
      console.log(bol, row, 'ssdf', this.ids, this.idsList)

      if (this.ids.length == this.listData.length) {
        this.checkedAll = true
      }
    },
    //冻结报告
    delcompany(row) {
      console.log(row)
      const query = {
        id: row.id
      }
      this.$alert('您是否确认冻结？')
        .then(function () {
          return freezeReport(query)
        })
        .then(() => {
          this.search()
          this.$message.success('冻结成功')
        })
        .catch(() => {})
    },
    //激活报告
    cancelcompany(row) {
      const query = {
        id: row.id
      }
      this.$alert('您是否确认激活？')
        .then(function () {
          return cancelFreezeReport(query)
        })
        .then(() => {
          this.search()
          this.$message.success('激活成功')
        })
        .catch(() => {})
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.checkedAll = false
      this.ids = []
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      localStorage.setItem('paginationCurrentPage', val)
      this.checkedAll = false
      this.ids = []
      this.search()
      // console.log(`当前页: ${val}`)
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: '';
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 2px;
  transform: rotate(45deg) scaleY(0);
  width: 4px;
  transition: transform 0.15s ease-in 50ms;
  transform-origin: center;
}
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
::v-deep .storeCenter_item_top_right .el-button{
  width: 125px;
}
.main {
  display: flex;
  flex-direction: column;
}

.arrUp {
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #c6c6c6;
  cursor: pointer;
}

.arrDown {
  width: 0;
  height: 0;
  margin-top: 2px;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-top: 8px solid #c6c6c6;
  cursor: pointer;
}

.label_list {
  display: flex;
  align-items: center;
  margin-top: 8px;
  .label {
    cursor: pointer;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    margin-right: 15px;
  }
  .acitve_label {
    padding: 0 10px;

    height: 28px;
    color: #146aff;
    background: #cbdeff;
    border-radius: 2px;
  }
}
.Subtag_list {
  padding: 10px;
  min-height: 77px;
  background: #f5f6f7;
  margin-top: 10px;
  display: flex;
  .label_title {
    width: 70px;
    flex-shrink: 0;
    white-space: nowrap;
    // span {
    //   font-family: 'PingFang SC';
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 12px;
    //   line-height: 17px;
    //   margin-right: 15px;
    //   color: #7c7f8e;
    // }
  }
  .label_box {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    div {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height */
      margin-right: 10px;
      color: #1f212b;
      // margin-bottom: 10px;
      height: 20px;
      padding: 5px 10px;
    }
    white-space: pre-wrap;
    .acitve_label {
      // height: 28px;
      color: #146aff;
      background: #cbdeff;
      border-radius: 2px;
    }
  }
}
.enterprise_dialog {
  .tip {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
  }
  .enterprise_box {
    .enterprise_item {
      margin-top: 10px;
      width: 387px;
      padding: 10px;
      background: #f4f4f4;
      border-radius: 1px;
      display: flex;
      justify-content: space-between;
      .left {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        /* identical to box height */

        color: #151515;
      }
      .right {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

        color: #fd5469;
      }
    }
  }
}
.comble_txt {
  span {
    margin-left: 20px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
}
.user {
  .container {
    height: calc(100vh - 150px);
    overflow: auto;
  }
  .form_info {
    background: #ffffff;
    padding: 20px;
  }

  .tab_txt {
    background: #f6f7fb;
    margin-top: 20px;
    padding: 0px;
    padding-bottom: 20px;
  }

  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }

  .input-with {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;

    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }
  .office {
    cursor: pointer;
    color: #4e93fb;
  }

  .support {
    cursor: pointer;
    color: #fd523f;
    margin-left: 12px;
  }
  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }

  .storeCenter_item {
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    .item_checkbox {
      line-height: 36px;
      margin: 0px 10px;
    }
    .Relevancy {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #ff7d18;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .Relevancyblue {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #146aff;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .storeCenter_item_top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      padding-bottom: 14px;
      position: relative;
      background: #ffffff;
      padding: 16px 20px 10px 10px;

      .storeCenter_item_top_left {
        display: flex;
        flex-direction: row;
        height: 107.73px;
        width: 450px;
        .banner_box {
          position: relative;
          width: 185.55px;
          height: 117.73px;
          .generalVersion {
            background: #448aff;
            border-radius: 2px;
            padding: 5px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            /* identical to box height */
            margin: 0 10px;
            color: #ffffff;
            position: absolute;
            left: 0px;
            top: 5px;
          }
          img {
            width: 185.55px;
            height: 117.73px;
            border-radius: 4px;
            margin-right: 14px;
          }
          .activity_state {
            position: absolute;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 34px;
            text-align: center;
            color: #ffffff;
            top: -10px;
            left: -9px;
            width: 78px;
            height: 34px;
            border-radius: 5px 15px 15px 0;
          }
          .not_started {
            background: #4e93fb;
          }
          .have_in_hand {
            background: #ff7d18;
          }
          .closed {
            background: #999999;
          }
        }

        .storeCenter_item_top_left_flex {
          margin-left: 10px;
          .h5_name {
            display: inline-block;
            margin: 0;
            font-size: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            width: 200px;
            -webkit-box-orient: vertical;
            margin-left: 5px;
            cursor: pointer;
          }
          .h5_names {
            display: inline-block;
            margin: 0;
            font-size: 17px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            width: 320px;
            -webkit-box-orient: vertical;
            cursor: pointer;
          }
          .mark_box {
            display: flex;
            img {
              width: 14px;
              // height: 14px;
            }
            & > div {
              margin-left: 10px;
            }
          }
          .img_commer {
            display: flex;
            border: 1px solid #146aff;
            border-radius: 39px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #146aff;
            }
            .color_img {
              background: #146aff;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }
          .img_commers {
            display: flex;
            border: 1px solid #ff9c00;
            border-radius: 39px;
            margin: 0px 10px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #ff9c00;
            }
            .color_img {
              background: #ff9c00;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }

          h4 {
            color: #1f2d3d;
            font-size: 26px;
            font-weight: 500;
            margin: 0;
          }

          .storeCenter_item_top_left_flex_tag {
            padding-top: 10px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-content: center;

            margin-bottom: 1px;
            width: 500px;

            .hidden_label_box {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-content: center;
              flex-wrap: wrap;
            }
            span {
              box-sizing: border-box;
              display: block;
              font-size: 14px;
              font-weight: 400;
              border-radius: 4px;
            }

            .office {
              border: 1px solid #559eff;
              color: #4e93fb;
              padding: 1px 6px;
              margin-right: 10px;
            }

            .support {
              border: 1px solid #fd523f;
              color: #fd523f;
              margin-left: 12px;
            }
          }

          .releaseMechanism {
            width: 450px;
            padding: 10px 0px;
            font-size: 16px;
          }
        }
      }
      .storeCenter_item_top_center {
        display: flex;
        .count_box {
          height: 80px;
          width: 80px;
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          cursor: pointer;
          &:nth-of-type(1) {
            border-right: 1px solid #e8e8e8;
          }
          & > div:nth-of-type(1) {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;

            color: #078bff;
          }
          & > div:nth-of-type(2) {
            margin-top: 20px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
          }
        }
      }
      .storeCenter_item_top_right {
        align-items: stretch;
        height: 100%;

        .operation_area {
          margin-top: 30px;
          .buttom_right {
            height: 36px;
            line-height: 1px;
          }
          .edit {
            padding-left: 20px;
            color: #4e93fb;
            cursor: pointer;
          }
          .frozen {
            padding-left: 20px;
            color: #fd5469;
            cursor: pointer;
          }
        }
        .details {
          text-align: right;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          color: #999999;
          margin-top: 5px;
        }
      }
    }
  }
}
</style>
